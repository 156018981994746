export const FACTORY_ADDRESS = '0x4BBdA880C5A0cDcEc6510f0450c6C8bC5773D499'

export const BUNDLE_ID = '1'

export const timeframeOptions = {
  WEEK: '1 week',
  MONTH: '1 month',
  // THREE_MONTHS: '3 months',
  // YEAR: '1 year',
  HALF_YEAR: '6 months',
  ALL_TIME: 'All time',
}

// all UPPER Case
export const jibTokenLists = [
  {
    name: 'JIBCHAIN',
    timestamp: '2024-03-06T15:16:00+07:00',
    version: {
      major: 3,
      minor: 2,
      patch: 0,
    },
    logoURI: 'https://jibswap.com/logo.png',
    keywords: ['jibswap', 'default', 'list', 'jibchain'],
    tokens: [
      {
        address: '0x99999999990FC47611b74827486218f3398A4abD',
        chainId: 8899,
        name: 'Wrapped JBC',
        symbol: 'WJBC',
        decimals: 18,
        logoURI: 'https://jibswap.com/images/tokens/WJBC.png',
      },
      {
        address: '0xFD8Ef75c1cB00A594D02df48ADdc27414Bd07F8a',
        chainId: 8899,
        name: 'USDT',
        symbol: 'USDT',
        decimals: 18,
        logoURI: 'https://jibswap.com/images/tokens/USDT.png',
      },
      {
        address: '0x55b25214363306686E1F5424d5f96Fb330F51D4c',
        chainId: 8899,
        name: 'USDC',
        symbol: 'USDC',
        decimals: 18,
        logoURI: 'https://jibswap.com/images/tokens/USDC.png',
      },
      {
        address: '0x17Fc6A15d85414fb7058ee7f54496763B8594344',
        chainId: 8899,
        name: 'ETH',
        symbol: 'ETH',
        decimals: 18,
        logoURI: 'https://jibswap.com/images/tokens/ETH.png',
      },
      {
        address: '0x0D89386044FF9975deCe36E8b85b6222B7B40A2c',
        chainId: 8899,
        name: 'OP',
        symbol: 'OP',
        decimals: 18,
        logoURI: 'https://jibswap.com/images/tokens/OP.png',
      },
      {
        address: '0xE6Af001741A2de21aEa4Bb1DA17d32d7f579c6a1',
        chainId: 8899,
        name: 'BNB',
        symbol: 'BNB',
        decimals: 18,
        logoURI: 'https://jibswap.com/images/tokens/BNB.png',
      },
      {
        address: '0x2cd11cdBeD000B5a680C015F2825569Bc5d8CF88',
        chainId: 8899,
        name: 'JFIN',
        symbol: 'JFIN',
        decimals: 18,
        logoURI: 'https://jibswap.com/images/tokens/JFIN.png',
      },
      {
        address: '0x28953231bc533bE90f5FDf9CE62Ac259018ee847',
        chainId: 8899,
        name: 'KUB',
        symbol: 'KUB',
        decimals: 18,
        logoURI: 'https://jibswap.com/images/tokens/KUB.png',
      },
      {
        address: '0x24599b658b57f91E7643f4F154B16bcd2884f9ac',
        chainId: 8899,
        name: 'JIB USDT',
        symbol: 'JUSDT',
        decimals: 18,
        logoURI: 'https://jibswap.com/images/tokens/JUSDT.png',
      },
      {
        address: '0x04052384166fC30D03929eE328805eC084776843',
        chainId: 8899,
        name: 'MEOW Token',
        symbol: 'MEOW',
        decimals: 18,
        logoURI: 'https://jibswap.com/images/tokens/MEOW.png',
      },
      {
        address: '0xc2744Ff255518a736505cF9aC1996D9adDec69Bd',
        chainId: 8899,
        name: 'Wood',
        symbol: 'WOOD',
        decimals: 18,
        logoURI: 'https://jibswap.com/images/tokens/CommuDAO/wood.png',
      },
      {
        address: '0x09676315DC0c85F6bd5e866C5f1363A00Eec4381',
        chainId: 8899,
        name: 'Tuna',
        symbol: 'TUNA',
        decimals: 18,
        logoURI: 'https://jibswap.com/images/tokens/CommuDAO/tuna.png',
      },
      {
        address: '0x09DE640ecd50e1c81bCB266279e3ffC2719873df',
        chainId: 8899,
        name: 'Mice',
        symbol: 'MICE',
        decimals: 18,
        logoURI: 'https://jibswap.com/images/tokens/CommuDAO/mice.png',
      },
      {
        address: '0x2a081667587c35956d34A4cC3bf92b9CA0ef2C6f',
        chainId: 8899,
        name: 'Silver',
        symbol: 'SIL',
        decimals: 18,
        logoURI: 'https://jibswap.com/images/tokens/CommuDAO/sil.png',
      },
      {
        address: '0x7d5346E33889580528e6F79f48BdEE94D8A9E144',
        chainId: 8899,
        name: 'Gold',
        symbol: 'GOLD',
        decimals: 18,
        logoURI: 'https://jibswap.com/images/tokens/CommuDAO/gold.png',
      },
      {
        address: '0xAc5299D92373E9352636559cca497d7683A47655',
        chainId: 8899,
        name: 'Oversoul',
        symbol: 'OS',
        decimals: 18,
        logoURI: 'https://jibswap.com/images/tokens/CommuDAO/os.png',
      },
      {
        address: '0x169816800f1eA9C5735937388aeb9C2A3bAca11F',
        chainId: 8899,
        name: 'Merit Token',
        symbol: 'MT',
        decimals: 18,
        logoURI: 'https://jibswap.com/images/tokens/CommuDAO/mt.png',
      },
      {
        address: '0x07008dA4C400b73A65ddD92B8eCa78e67201407b',
        chainId: 8899,
        name: 'Potential Token',
        symbol: 'POT',
        decimals: 18,
        logoURI: 'https://jibswap.com/images/tokens/POT.png',
      },
      {
        address: '0x999999999F7790f330Faa37457741d3e26A74659',
        chainId: 8899,
        name: 'JIB POINT',
        symbol: 'JBP',
        decimals: 18,
        logoURI: 'https://jibswap.com/images/tokens/JBP.png',
      },
      {
        address: '0xa4C33E04d8ac31D99cECfF5877049E3e93448721',
        chainId: 8899,
        name: 'Class Coin',
        symbol: 'CLASS',
        decimals: 18,
        logoURI: 'https://jibswap.com/images/tokens/CLASS.png',
      },
      {
        address: '0xB68242a81eD773a0030725AEd49A13003799B255',
        chainId: 8899,
        name: 'JIB404',
        symbol: 'JIB404',
        decimals: 18,
        logoURI: 'https://jibswap.com/images/tokens/JIB404.png',
      },
      {
        address: '0x027B63f79Af3C3192C43ddC7C30F84de16689bff',
        chainId: 8899,
        name: 'Stake JBC',
        symbol: 'stJBC',
        decimals: 18,
        logoURI: 'https://jibswap.com/images/tokens/stJBC.png',
      },
      {
        address: '0xebe937eE67E3219D176965CC08110A258f925e01',
        chainId: 8899,
        name: 'JIBJIB',
        symbol: 'JIBJIB',
        decimals: 18,
        logoURI: 'https://jibswap.com/images/tokens/JIBJIB.png',
      },
      {
        address: '0x1BcEF281646008Eb27d70A01494E38cEEB1866d2',
        chainId: 8899,
        name: 'SAKU Token',
        symbol: 'SAKU',
        decimals: 18,
        logoURI: 'https://jibswap.com/images/tokens/SAKU.png',
      },
    ],
  },
]

export const findLogoURIByAddress = (address) => {
  const tokenList = jibTokenLists[0]
  const token = tokenList.tokens.find((token) => token.address === address)
  return token ? token.logoURI : 'unsupported'
}

// token list urls to fetch tokens from - use for warnings on tokens and pairs
export const SUPPORTED_LIST_URLS__NO_ENS = [
  'https://jibswap.com/tokens.json',
  // 'https://gateway.ipfs.io/ipns/tokens.jibswap.com',
  // 'https://www.coingecko.com/tokens_list/uniswap/defi_100/v_0_0_0.json',
]

// hide from overview list
export const TOKEN_BLACKLIST = []

// pair blacklist
export const PAIR_BLACKLIST = []

// warnings to display if page contains info about blocked token
export const BLOCKED_WARNINGS = {}

/**
 * For tokens that cause erros on fee calculations
 */
export const FEE_WARNING_TOKENS = []

export const UNTRACKED_COPY = 'Derived USD values may be inaccurate without liquid stablecoin or ETH pairings.'

// pairs that should be tracked but arent due to lag in subgraph
export const TRACKED_OVERRIDES_PAIRS = [
  '0xc2df3C4Be65E7Cd5E86889e350702b9d1949Dc2B', // WJBC-USDT
  '0xbb507f6bc443473532ae60d33ec6d36e2d3dbb0d', // WJBC-JUSDT
  '0x04C106B2E51a307D3c69d2a72c5A24dcF7c35731', // MEOW-WJBC
  '0x54d2a2e2961762cfe15d5151b99ac7d255aa2d40', // MEOW-JUSDT
  '0xCd4d7D71037C271f5D1820eCb5e4026ca3E21286', // MEOW-USDT
  '0x0e2302d286778b70f59451036207aec484c15198', // MEOW-WOOD
  '0x1a2a8039c4b685d47b9ef6e2c9d3acb5ab83bcac', // MEOW-GOLD
  '0x2038f97fe5199a473726016c6b755c71a1d02fa0', // MEOW-SIL
  '0xb882c5ec427de3085127c89de4fe3cee12b345bb', // MEOW-MT
  '0xcc59b348085384c1b8e4d310e53a4823acd38653', // MEOW-MICE
  '0xf9c16d28e8113d0966ce060a4f8c4cfad6d87467', // MEOW-TUNA
  '0xc33ed5700b34df55c925b4ca1791aa0f61c6bced', // MEOW-stOPT
  '0x7400281c02259436ca6bbf2bcd6624e7e497ee96', // MEOW-OS
  '0x07Ed6d5EC72d915d77F405e78642281FC07F7595', // USDT-USDC
  '0x3203d8e5957dae4195794880A9b54Fad155B3D1f', // WJBC-USDC
  '0x5528b09f28cebe19e97d1d5af5d7fbd86a6071e9', // WJBC-JIBJIB
  '0xa4a17ce04f592ecf6f9f8e417d98fcba5adf874b', // SAKU-WJBC
  '0xe036e7b1becf6cce324f2f93bd4e0ae31354a7b0', // FNX-WJBC
  '0x9baf77e2d1c1de6c2b53e77d47625de4b9e62b67', // WJBC-JIB404
]

// tokens that should be tracked but arent due to lag in subgraph
// all pairs that include token will be tracked
export const TRACKED_OVERRIDES_TOKENS = [
  '0x99999999990FC47611b74827486218f3398A4abD', // WJBC
  '0xFD8Ef75c1cB00A594D02df48ADdc27414Bd07F8a', // USDT
  '0x55b25214363306686E1F5424d5f96Fb330F51D4c', // USDC
  '0x17Fc6A15d85414fb7058ee7f54496763B8594344', // ETH
  '0x0D89386044FF9975deCe36E8b85b6222B7B40A2c', // OP
  '0xE6Af001741A2de21aEa4Bb1DA17d32d7f579c6a1', // BNB
  '0x2cd11cdBeD000B5a680C015F2825569Bc5d8CF88', // JFIN
  '0x28953231bc533bE90f5FDf9CE62Ac259018ee847', // KUB
  '0x24599b658b57f91e7643f4f154b16bcd2884f9ac', // JUSDT
  '0x04052384166fC30D03929eE328805eC084776843', // MEOW
  '0xc2744Ff255518a736505cF9aC1996D9adDec69Bd', // WOOD
  '0x09676315DC0c85F6bd5e866C5f1363A00Eec4381', // TUNA
  '0x09DE640ecd50e1c81bCB266279e3ffC2719873df', // MICE
  '0x435BeAF4B83A6dc57927E9dB194a3Ccf54100F7a', // stOPT
  '0x2a081667587c35956d34A4cC3bf92b9CA0ef2C6f', // SIL
  '0x7d5346E33889580528e6F79f48BdEE94D8A9E144', // GOLD
  '0xAc5299D92373E9352636559cca497d7683A47655', // OS
  '0x169816800f1eA9C5735937388aeb9C2A3bAca11F', // MT
  '0xebe937eE67E3219D176965CC08110A258f925e01', // JIBJIB
  '0x343f6090d9400dc40b71d70cb3669f60465db920', // SAKU
  '0x39bfaa36cb6b0d47146e2b4694e2f8eaae688a4b', // FNX
  '0x07008dA4C400b73A65ddD92B8eCa78e67201407b', // POT
  '0x999999999F7790f330Faa37457741d3e26A74659', // JBP
  '0xa4C33E04d8ac31D99cECfF5877049E3e93448721', // CLASS
  '0xb68242a81ed773a0030725aed49a13003799b255', // JIB404
]
